export function shallowEqualArray(listA, listB) {
  if (listA.length !== listB.length) return false;
  for (let i = 0; i < listA.length; i += 1) {
    if (listA[i] !== listB[i]) return false;
  }
  return true;
}

export function createSimpleMemoizer(fn) {
  let lastArgs = null;
  let lastResult = null;
  return (...args) => {
    if (lastArgs && shallowEqualArray(lastArgs, args)) return lastResult;
    lastArgs = args;
    lastResult = fn(...args);
    return lastResult;
  };
}

export function createRefMemoizer() {
  return createSimpleMemoizer((...refFns) => n => refFns.forEach(refFn => refFn(n)));
}

export function isIE11() {
  if (typeof window !== "undefined" && window.navigator) {
    const {userAgent} = window.navigator;
    return userAgent.indexOf("MSIE ") > 0 || !!userAgent.match(/Trident.*rv:11\./);
  }
  return false;
}

export const errorToString = (maybeError, fallback) => {
  if (!maybeError) return "Unknown error";
  if (typeof maybeError === "string") return maybeError;
  if (maybeError.message) return errorToString(maybeError.message);
  if (maybeError.error) return errorToString(maybeError.error);
  if (maybeError.payload) return errorToString(maybeError.payload);
  console.error("Couldn't interpret error", maybeError);
  try {
    return JSON.stringify(maybeError).slice(0, 60);
  } catch {
    return fallback !== undefined ? fallback : "Unknown error";
  }
};

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const isExternalUrl = url => {
  if (!url) return false;
  if (!url.startsWith("http")) return false;
  if (process.env.GATSBY_COOKIE_DOMAIN && url.includes(process.env.GATSBY_COOKIE_DOMAIN))
    return false;
  return true;
};
